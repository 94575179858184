import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import "./style.css";
import VerifyKyc from "../VerifyKyc";
import { CSVLink } from "react-csv";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import DataTableBase from "../../../customComponent/DataTable";
import AddFipcoinPopup from "../../../customComponent/AddFipcoinPopup";

const AllUsers = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [allFilterData, setFilterAllData] = useState([]);
  const [activeScreen, setActiveScreen] = useState("pending");
  const [userId, setUserId] = useState("");
  const [userKycData, setuserKycData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = (id) => {
      console.log(id)
      setUserId(id)
      setModalOpen(!modalOpen)
    };

  const handleRowSelect = (rowId) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(rowId)
        ? prevSelected.filter((id) => id !== rowId) // Deselect
        : [...prevSelected, rowId] // Select
    );
  };

  const linkFollow = (row) => {
    return (
      <button className="btn btn-dark btn-sm " onClick={()=>toggleModal(row.code)}>
        Modify FIPCOIN
      </button>
    );
  };

  function imageFormatter(row) {
    return (
      <img style={{ width: "40%", height: "auto" }} className="table-img" src={ApiConfig?.appUrl + row?.user_selfie} alt="Selfie" />
    );
  };

  const columns = [
    {
      name: '#',
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(row.emailId)}
          onChange={() => handleRowSelect(row.emailId)}
        />
      ),
      ignoreRowClick: true, // Prevent row click from selecting the checkbox
      width: '50px',
    },
    { name: "Date", selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), },
    // { name: "ID", wrap: true, selector: row => row._id, },
    { name: "FirstName", selector: row => row.firstName, },
    { name: "LastName", selector: row => row.lastName, },
    { name: "EmailId", wrap: true, selector: row => row.emailId, },
    // { name: "Pan Number",wrap: true, selector: row => row.pancard_number, },
    // { name: "Selfie", selector: imageFormatter, },
    // { name: "DOB", selector: row => row.dob, },
    { name: "Action", selector: linkFollow, },
  ];

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getAlldata().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setData(result.data.reverse());
          setAllData(result.data.reverse());
          setFilterAllData(result.data.reverse());
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
      }
    });
  };

  const filter = (val) => {
    console.log(val)
    if(val != 0){
      const filtered = allData.filter((x)=> x.kycVerified == val);
      setFilterAllData(filtered)
    }
    else
      setFilterAllData(allData);
  }

  const sendMail = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.sendEmail({ emails: selectedRows }).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage("Mail send successfully")
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
      }
    });
  }


  function searchObjects(e) {
    
    const keysToSearch = ["firstName", "userId", "emailId", "pancard_number", "dob"];
    // const userInput = e.target.value;0
    const searchTerm = e.target.value?.toLowerCase();
    if(searchTerm){
      const matchingObjects = allData.filter(obj => {
        return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
      });
      console.log(matchingObjects)
      setFilterAllData(matchingObjects);
    }
  }

  return activeScreen === "pending" ? (
    <div id="layoutSidenav_content">
      <AddFipcoinPopup user_id={userId} isOpen={modalOpen} toggle={toggleModal}/>
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className=" fa fa-user-slash"></i>
                    </div>
                    All Users
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header">
              All Users List
              <div className="col-3">
                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={(e)=>searchObjects(e)} />
                
              </div>
              <div className="col-2">
              <select className="form-control form-control-solid form-select" onChange={(e) => filter(e.target.value) }>
                    <option value={0}>All</option>
                    <option value={2}>Approved</option>
                </select>
              </div>
              <button className="btn btn-dark btn-sm" id="dropdownFadeInUp" type="button" onClick={() => sendMail()}>
                Send Mail
              </button>
              <div className="dropdown">
                <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <i className="fa fa-download me-3"></i>Export
                </button>
                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp" >
                  <CSVLink data={data} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase columns={columns} data={allFilterData} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <VerifyKyc userId={userId} kycData={userKycData} />
  );
};
export default AllUsers;
