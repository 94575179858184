'use client'
import { useFormik } from 'formik';
import { Input, Form, FormFeedback, Modal, ModalHeader, ModalBody, Table, Button } from 'reactstrap';
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from 'react';
import AuthService from '../api/services/AuthService';
import LoaderHelper from './Loading/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from './CustomAlertMessage';
import { format } from 'date-fns';

const AddFipcoinPopup = ({ isOpen, toggle, user_id }) => {
    const [availCoins, setAvailCoins] = useState(0)
    const [coinsList, setCoinsList] = useState([])

    const fetchCoins = async () => {
        await AuthService.fetchFipcoins(user_id).then(async (result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    const totalAmount = result?.data.reduce((acc, transaction) => acc + transaction.transaction_amount, 0);
                    setAvailCoins(totalAmount)
                    setCoinsList(result?.data?.filter((x) => x.transaction_remarks == "FIPCOIN"))
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    useEffect(() => {
        if (user_id != null && user_id !== undefined && user_id != "") {
            fetchCoins();
        }
    }, [user_id])
    const Validation = useFormik({
        initialValues: {
            coins: "",
            submit: ""
        },
        validationSchema: Yup.object({
            coins: Yup.number().required('coins is required')
            .positive('coins must be positive')
            .min(1, 'coins must be greater than or equal to 1')
        }),

        onSubmit: async (values) => {
            LoaderHelper.loaderStatus(true);
            const min = 10000;
            const max = 1000000;
            const rand = min + Math.random() * (max - min);
            await AuthService.addFipcoins(user_id, rand.toString(), parseInt(values.coins), "FIPCOIN", "", 0).then(async (result) => {
                if (result.success) {
                    LoaderHelper.loaderStatus(false);
                    try {
                        Validation.resetForm();
                        // toast("Form sent successfully!")
                        // toggle(null)
                        fetchCoins();
                        alertSuccessMessage("Fipcoin added successfully")
                    } catch (error) {
                        alertErrorMessage(error);
                    }
                } else {
                    LoaderHelper.loaderStatus(false);
                }
            });

        }
    })
    const removeCoin = async(id)=>{
        await AuthService.removeCoin(id).then(async (result) => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    fetchCoins()
                    alertSuccessMessage("Fipcoin removed successfully")
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertSuccessMessage("Fipcoin removed Failed")
                LoaderHelper.loaderStatus(false);
            }
        });
    }
    const closeBtn = (
        <button className="btn close" onClick={() => toggle(null)} >
            &times;
        </button>
    );
    return (
        <Modal centered={true} size="lg" className='application-popup' isOpen={isOpen} toggle={() => toggle(null)}>
            <ModalHeader toggle={toggle} close={closeBtn}>Add/Remove Fipcoins</ModalHeader>
            <ModalBody>
                <div className="container align-items-center">
                    <div className="col-6 offset-3 fipcoin-content form-bg ">
                        <div className="col-lg-12">
                            <Form onSubmit={e => {
                                e.preventDefault();
                                Validation.handleSubmit();
                                return false;
                            }}>
                                <div className="contact-form">
                                    <div className="row gap-3">
                                        <div className="section-field  col-12">
                                            <p className='mb-0 text-dark text-center'>Available Coins : {availCoins} (Fipcoins)</p>
                                        </div>
                                        <div className="section-field col-12">
                                            <Input name="coins" placeholder="Coins*" type="text" className="require" onChange={Validation.handleChange} onBlur={Validation.handleBlur} value={Validation.values.coins || ""} invalid={Validation.touched.coins && Validation.errors.coins ? true : false} />
                                            {Validation.touched.email && Validation.errors.coins ? (<FormFeedback typeof="invalid">{Validation.errors.coins}</FormFeedback>) : null}
                                        </div>
                                        <div className="d-flex justify-content-center text-center text-sm-center">
                                            <button id="submit" name="submit" type="submit" className="btn btn-primary btn-sm">Add Coins</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="col-12 fipcoin-content ">
                        <Table bordered className='table mt-4'>
                            <tr><th>Date</th><th>Coins</th><th>#</th></tr>
                            {coinsList.map((item) => {
                                return (<tr ><td className='py-2'>{format(item.createdAt, 'dd/MM/yyyy')}</td><td className='py-2'>{item.transaction_amount}</td><td className='py-2'>
                                    <Button  size='sm' onClick={()=> removeCoin(item._id)} color='danger' outline >Remove</Button>
                                </td></tr>)
                            })}
                        </Table>

                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}
export default AddFipcoinPopup;